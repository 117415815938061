import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { UiProvider } from '@wbk/ui';
import { WbkTicketingProvider } from '@wbk/ticketing';
import { AnalyticsProvider } from '@wbk/analytics';
import { AuthProvider } from '@wbk/auth';
import { WbkConfigProvider } from '@wbk/config';
import { CurrencyProvider } from '@wbk/currency';
import { useGetSupportedCurrencies } from '@wbk/api/hooks';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from '../constants/fallbackLogo';
import { gtmTracker } from '../../trackers.config';

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: Props) => {
  const { locale = 'en', pathname, push, replace, locales } = useRouter();
  const { t } = useTranslation();
  const { data: currencies } = useGetSupportedCurrencies({ lang: locale });

  return (
    <WbkConfigProvider
      i18n={{ useTranslation, Trans, languages: locales || [] }}
      router={{
        Link,
        push,
        replace,
        locale,
      }}
      appSource={process.env.NEXT_PUBLIC_APP_SOURCE!}
    >
      <UiProvider imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}>
        <AnalyticsProvider trackers={[gtmTracker]} currentLocation={pathname}>
          <AuthProvider
            autoInit={false}
            newsLetter={{ organizationName: t('common:title') }}
            poweredBy
          >
            <CurrencyProvider
              detectedCurrency='SAR'
              currencies={(currencies || []).map((c) => ({
                ...c,
                shortCode: c.short_code,
                symbol: c.currency_symbol || '',
                svgPath: c.svg_path,
                svgViewbox: c.svg_viewbox,
              }))}
            >
              <WbkTicketingProvider
                seatsIO={{
                  workspace: process.env.NEXT_PUBLIC_SEATIO_WORKSPACE_KEY,
                }}
                resell={{
                  terms: t('booking:resell_terms_agree'),
                }}
              >
                {children}
              </WbkTicketingProvider>
            </CurrencyProvider>
          </AuthProvider>
        </AnalyticsProvider>
      </UiProvider>
    </WbkConfigProvider>
  );
};

export default AppProviders;
