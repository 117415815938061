import Copyright from './Copyright';

const MiniFooter = () => {
  return (
    <footer className='hidden bg-body-level-1 p-4 lg:block'>
      <div className='container mx-auto flex flex-wrap items-center justify-center gap-4'>
        <Copyright />
      </div>
    </footer>
  );
};

export default MiniFooter;
