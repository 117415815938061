import Head from 'next/head';
import { Router } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import NProgress from 'nprogress';
import { Toaster } from 'react-hot-toast';
import { QueryProvider } from '@wbk/api/hooks';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import OfflineState from '@/components/common/OfflineState';
import CookieConsent from '@/components/common/CookieConsent';
import Layout from '@/components/layout';
import SocialJsonLd from '@/components/headers/seo/SocialJsonLd';
import DefaultSeo from '@/components/headers/seo/DefaultSeo';
import AppProviders from '../context/providers';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import '../../api.config';
import '../../logger.config';

/**
 * Ng Progress
 */
const handleRouteStart = () => NProgress.start();
const handleRouteDone = () => NProgress.done();
Router.events.on('routeChangeStart', handleRouteStart);
Router.events.on('routeChangeComplete', handleRouteDone);
Router.events.on('routeChangeError', handleRouteDone);

function App({ Component, pageProps }: AppProps<LayoutProps>) {
  return (
    <>
      <Head>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
      </Head>
      <ErrorBoundary>
        <QueryProvider>
          <AppProviders>
            <SocialJsonLd />
            <DefaultSeo />
            <Toaster position='top-center' />
            <Layout {...pageProps}>
              <Component {...pageProps} />
            </Layout>
            <CookieConsent />
            {/* Offline notice */}
            <OfflineState />
          </AppProviders>
        </QueryProvider>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(App);
