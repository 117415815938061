import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';
import { Button, Popover } from '@wbk/ui';
import { LogoutModal, useAuth } from '@wbk/auth';

const LINKS = [
  {
    href: '/profile',
    title: 'my_info',
    icon: 'user',
  },
  {
    href: '/profile/bookings',
    title: 'my_bookings',
    icon: 'ticket',
  },
];

const UserMenu = () => {
  const { locale: lang, asPath } = useRouter();
  const { t } = useTranslation(['common', 'profile']);
  const [isOpened, setIsOpened] = useState(false);
  const { user, fetchUser, loading } = useAuth({ autoFetch: isOpened });

  const close = () => setIsOpened(false);

  const content = (
    <>
      {loading ? (
        <div className='space-y-4'>
          <div className='flex items-center gap-4'>
            <div className='h-12 w-12 animate-pulse rounded-full bg-body-dark/10'></div>
            <div className='h-8 grow animate-pulse rounded-lg bg-body-dark/10'></div>
          </div>

          <div className='space-y-2'>
            <div className='h-8 grow animate-pulse rounded-lg bg-body-dark/10'></div>
            <div className='h-8 grow animate-pulse rounded-lg bg-body-dark/10'></div>
            <div className='h-6 grow animate-pulse rounded-lg bg-body-dark/10'></div>
          </div>
        </div>
      ) : !user ? (
        <div className='mx-auto max-w-[200px] py-4'>
          <Button
            as={Link}
            href={`/${lang}/login?redirect=${asPath}`}
            theme='white'
            shape='outlined'
            data-testid='login-profile'
            data-location='header'
            className='w-full !px-1.5 !py-1.5 md:!px-2.5'
            role='menuitem'
          >
            <p>{t('common:auth.login_signup')}</p>
          </Button>
        </div>
      ) : (
        <>
          <div className='flex w-full items-center gap-4'>
            <div className='relative flex h-12 w-12 items-center justify-center rounded-full bg-primary text-xl font-bold uppercase text-primary-contrast sm:mb-0'>
              {`${user.first_name?.[0]}${user.last_name?.[0]}`}
            </div>
            <div className='text-lg font-bold'>
              <p>{user.name}</p>
              <p className='text-xs font-normal text-gray-500'>{user.email}</p>
            </div>
          </div>
          <ul className='space-y-1.5'>
            {LINKS.map((link) => (
              <li key={link.href}>
                <Link
                  href={`/${lang}${link.href}`}
                  className='flex w-full items-center justify-between rounded-md bg-body-level-2 p-2 hover:opacity-80'
                  data-menuitem={link.title}
                  data-location='user-menu'
                  onClick={close}
                >
                  <div className='flex items-center gap-3 font-semibold'>
                    <Image
                      className='shrink-0'
                      src={`/icons/profile/${link.icon}.svg`}
                      width={25}
                      height={25}
                      alt=''
                    />
                    {t(`common:auth.${link.title}`)}
                  </div>

                  <Image
                    className='shrink-0 text-white rtl:rotate-180'
                    src='/icons/common/arrow.svg'
                    width={25}
                    height={25}
                    alt=''
                  />
                </Link>
              </li>
            ))}

            <li>
              <LogoutModal
                onClosed={close}
                render={({ open }) => (
                  <button
                    onClick={() => open()}
                    className='flex w-full cursor-pointer rounded-md p-2 text-red-500 hover:bg-body-level-2 hover:underline'
                    data-menuitem='logout'
                    data-location='user-menu'
                  >
                    {t('common:nav.logout')}
                  </button>
                )}
              />
            </li>
          </ul>
        </>
      )}
    </>
  );

  return (
    <>
      <Popover
        align='end'
        sideOffset={14}
        className='w-96 max-w-full [&>div]:space-y-4 [&>div]:p-4'
        open={isOpened}
        onOpenChange={setIsOpened}
        content={content}
      >
        <button
          className='flex items-center gap-1 text-sm'
          onClick={() => {
            if (!user && !loading) {
              fetchUser();
            }
          }}
        >
          <div
            className={twMerge(
              'font-gellix flex h-8 w-8 items-center justify-center rounded-full p-1 uppercase',
              user ? 'bg-primary text-primary-contrast' : 'bg-neutral-500'
            )}
          >
            {user ? (
              `${user.first_name?.[0]}${user.last_name?.[0]}`
            ) : (
              <Image
                className='shrink-0'
                src='/icons/profile/user.svg'
                width={17}
                height={17}
                alt='user icon'
              />
            )}
          </div>
          <svg width='10' height='7' className='shrink-0 rotate-180'>
            <path
              d='M9.91148 5.92015L5.30211 0.792139C5.16825 0.643212 4.93098 0.624239 4.77216 0.749759L4.74901 0.769661L0.0892809 5.91931C-0.0450745 6.06785 -0.0255751 6.29039 0.132834 6.41637C0.200789 6.47042 0.287 6.50008 0.376106 6.50008H9.6239C9.83161 6.50008 10 6.34219 10 6.14742C10 6.06425 9.96865 5.98375 9.91148 5.92015Z'
              fill='currentColor'
            />
          </svg>
        </button>
      </Popover>
    </>
  );
};

export default UserMenu;
